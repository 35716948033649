export default {
  brokvisor: "brokvisor",
  mobile_message:
    "Hola, de momento esta resolución no está disponible, accede desde un portátil, tablet o una pantalla más grande. Disculpa las molestias.",
  generic: {
    buttons: {
      add: "Agregar",
      replace: "Sustituir",
      add_to_basket: "Añadir a cesta",
      search: "Buscar",
      load_wallet: "Abra cartera",
      search_funds: "Buscar fondos",
      switch_to_simple: "Cambia a vista simple",
      generate_portfolio: "Genera cartera",
      switch_simple_mode: "Vista simplificada",
      switch_advance_mode: "Vista avanzada",
      portfolio: "Cartera",
      basket: "Cesta",
      loading: "Cargando...",
      reset_filters: "Volver a valores predeterminados",
      continue: "Continuar",
      select_portfolio: "Selecciona la(s) cartera(s)",
      option: "Opción",
      family: "Agregar clase",
      benchmark: "Gráfica junto a Cartera",
      cancel: "Cancelar",
      ok: "Aceptar",
      pay: "Pagar",
      portfolio_summary: "Resumen cartera",
      asset_basket: "Cesta Activos",
      asset_portfolio: "Activos Cartera",
      portfolios : "Carteras",
      graph_view: "Gráfico de cartera",
      funds_view: "Gráfico de activos",
      fix_weights: "Fijar",
      all: "Todo",
      remove_search: "Eliminar búsqueda",
      save: "Guardar",
      open_portfolio_control: "Abrir panel de control",
      show_portfolio: "Mostrar cartera",
      close: "Close",
      purchase: "Compra",
      portfolio_actions: {
        'open': 'Abrir',
        'edit': 'Parámetros',
        'remove': 'Eliminar',
        'rename': 'Renombrar',
        'send_mail': "Correo",
        'will_receive_mail': "Recibirás un correo electrónico con el resumen en los próximos minutos."
      },
      portfolio_actions_tooltips: {
        'open': "Abrir la cartera seleccionada",
        'edit': "Cambiar los parámetros de la cartera",
        'remove': "Eliminar la cartera seleccionada",
        'rename': "Cambiar el nombre de la cartera seleccionada",
        'send_mail': "Enviar correo electrónico con resumen de la cartera",
      },
    },
    titles: {
      recommendations : "Ideas generadas por IA",
      active_plan: "Tu plan activo",
      select_family: "Selecciona la familia que desea agregar a la cesta",
      predefined_search:
        "Selección de fondos de nuestra IA ",
      filter_criteria: "Selecciona criterios para filtrar",
      limit_risk: "Selecciona categorias",
      limit_control: "Selecciona límites",
      optimize_portfolio: "Optimiza cartera",
      fix_weights: "Fijar pesos",
      generate_portfolio: "Genera cartera",
      load_portfolio: "Mis carteras",
      load_portfolio_text: "Selecciona una o varias carteras para poder seguirlas en Brokvisor.",
      import_portfolio: "Importa cartera",
      search_funds: "Buscar Fondos",
      search_stocks: "Buscar Acciones",
      search_cryptos: "Buscar Cryptos",
      search_benchmark: "Buscar Índices",
      search_etfs: "Busca ETF",
      search_pensions: "Busca Pensiones",
      countries: "Países",
      sectors: "Sectores",
      industries: "Industrias",
      units: "Unidades",
      operator: "Operador",
      changes_ok: "Cambios aplicados",
      new_password_message: "Enlace enviado",
      error: "Error",
      user: "Usuario",
      select_columns: "Personalizar tabla",
      select_columns_tooltip: "Personaliza las columnas que se mostrarán en la tabla.",
      select_deselect: "Seleccionar/deseleccionar todo",
      category: "Categoría",
      percent: "%",
      select_search: "Selecciona una búsqueda",
      top: "Top",
      bests_modal_title: "Se han encontrado %{total} resultados",
      clean_basket: "Limpia cesta antes de agregar",
      elements_by_page: "Elementos por página",
      available_text: "Selecciona entre las columnas disponibles.",
      no_available_text: "Todas las columnas ya han sido seleccionadas.",
      no_fund_for_graph: "Seleccione hasta 20 activos",
      table_length: "resultados",
      weekly_agenda: "Calendario económico semanal",
      see_all_faqs: "Ver todas las FAQs",
      see_less_faqs: "Ver menos FAQs",
      no_opened_portfolio: "No tienes ninguna cartera abierta.",
      chat_input_placeholder: "Haz tu pregunta aquí...",
      edit_portfolio_params: "Editar Parametros cartera",
      delete_portfolios: "¿Está seguro de que desea eliminar este portafolio?",
      portfolio_control_panel: "Panel de control de carteras",
      price: "Precio",
      period: "Período",
      currency_code: "Código de moneda",
      and: "y la",
      agreement_label: "Acepto los ",
      terms_of_service: "Términos de Servicio",
      privacy_policy: "Política de Privacidad."
    },
    page_infos: {
      import_portfolio: "Puedes generar tus portafolios desde el csv en esta página.",
      advanced_generate_portfolio: "Puedes generar tus carteras aquí con algunos límites y utilizando nuestra avanzada tecnología de IA.",
      optimize_portfolio: "Optimice su cartera actual utilizando mejores riesgos y límites. También puede utilizar los límites ya guardados aquí.",
      fix_weights: "Corrija los pesos de su cartera actual ajustándolos manualmente. Intente observar las columnas de simulación de la siguiente tabla.",
    },
    disclaimer: "La información contenida en esta publicación no ha sido aprobada por la Comisión Nacional del Mercado de Valores. Esta publicación se lleva a cabo únicamente con fines informativos. Ni esta publicación ni su contenido constituyen una oferta, solicitud o invitación de compra, un acuerdo o compromiso de inversión o decisión de ningún tipo. Esta publicación no constituye asesoramiento específico de inversión para comprar o vender cualquier inversión o suscribir cualquier contrato de inversión o cualquier otro servicio financiero ni implica un asesoramiento o recomendación alguna de orden fiscal. Recomendamos que cualquier decisión que se adopte de índole fiscal sea consultada con abogados y/o asesores fiscales. No debe utilizar esta publicación como base para tomar sus decisiones financieras. Todas las previsiones, opiniones, estimaciones y proyecciones contenidas en esta publicación constituyen el juicio de BROKVISOR o de terceras personas de reconocido prestigio y se proporcionan únicamente con fines ilustrativos. Tales previsiones, opiniones, estimaciones y proyecciones implican riesgos conocidos y desconocidos, incertidumbres y otros factores que pueden hacer que los resultados, el rendimiento o los logros reales sean materialmente diferentes de los resultados, el rendimiento o los logros futuros expresados o implícitos en tales previsiones, opiniones, estimaciones y proyecciones. La rentabilidad pasada no es una guía fiable de la rentabilidad futura. El valor de las inversiones y los ingresos derivados de ellas pueden bajar o subir y es posible que no recupere el importe invertido.",
    disclaimer_morning_star: "© 2024 Morningstar. All Rights Reserved. The information contained herein: (1) is proprietary to Morningstar and/or its content providers; (2) may not be copied or distributed; and (3) is not warranted to be accurate, complete or timely. Neither Morningstar nor its content providers are responsible for any damages or losses arising from any use of this information. Pastperformance is no guarantee of future results.",
    empty_basket_title: "Tu cesta de activos está vacía",
    empty_basket_desc: "Busca y analiza los mejores fondos y activos. Aprovecha nuestras búsquedas predefinidas para que nuestra IA elija la estrategia más conveniente para ti en este momento. En tu cesta podrás analizarlos y crear un pdf para ver el detalle de todos los activos juntos.",
    no_selected_portfolio: "No tienes ninguna cartera abierta",
    portfolio_params: {
      risk: "Riesgo",
      depository: "Depositario",
      group: "Grupo cartera",
      include_benchmark: "Incluir benchmark",
      no_rebalance: "Rebalanceo automático",
      include_compositions: "Incluir cuadro composiciones",
      created_at: "Fecha de Alta",
      updated_at: "Fecha de Último Rebalanceo",
      send_mail_every: "Enviar mails cada",
      days_between_reviews: "Días entre revisiones",
      value_at: "Valor a",
      mandate: "Mandato",
      delete_positions: "Borrar posiciones",
      true: "Sí",
      false: "No",
      to: "A",
      confirm_delete_positions: "¿Estás segura de eliminar estas posiciones?",
      value_at_options: {
        "LM": "Último mes cerrado",
        "LD": "Último día cerrado"
      },
      send_mail_options: {
        "none": "Nunca",
        "d": "Día(s)",
        "w": "Semana(s)",
        "m": "Mes(es)",
      }
    },
    filter_conditions: {
      equals: 'Igual',
      not_equal: 'Distinto',
      blank: 'Sin valor',
      not_blank: 'Con valor',
      empty: 'Seleccina uno',
      less_than: 'Menor que',
      greater_than: 'Mayor que',
      less_than_or_equal: 'Menor o igual',
      greater_than_or_equal: 'Mayor o igual',
      in_range: 'Entre',
      in_range_start: 'Desde',
      in_range_end: 'Hasta',
      contains: 'Contiene',
      not_contains: 'No contiene',
      starts_with: 'Comienza con',
      ends_with: 'Termina con',
    },
    options: {
      select_one: "Selecciona uno",
      unlimmited: "Sin limites",
      more_that: "(>) Mayor que",
      less_that: "(<) Menor que",
      same_as: "(=) Igual a",
    },
    risk_options: {
      Conservador: "Conservador",
      Moderado: "Moderado",
      Equilibrado: "Equilibrado",
      Dinamico: "Dinámico",
      Agresivo: "Agresivo",
    },
    toast: {
      session_expired_title: "Sesión caducada",
      session_expired_description: "Necesita volver a iniciar sesión",
      generic_error_title: "Error",
      generic_error_description: "Ha ocurrido un error al recuperar datos",
      generic_warning_title: "Atención",
    },
    forms: {
      tkr_name: "Tkr/Nombre",
      company: "Compañía",
    },
  },
  chart: {
    range: {
      yearToDate: 'YTD',
      all: 'All'
    },
    zoom: 'Períodos'
  },
  agenda: {
    forecast: "Previsión",
    event: "Evento",
    currency: "Divisa",
    region: "Región",
    hour: "Hora",
    actual: "Actual",
    previous: "Anterior"
  },
  faqs: {
    types: {
      'Portfolio': "Carteras",
      'General': "General",
      'Users': "Usuarios"
    }
  },
  pdf: {
    portfolio_report_title: "Informe de cartera",
    asset_report_title: "Informe de activos",
    page: "Página",
    of: "de"
  },
  generate_portfolio: {
    text: "Crea las mejores carteras usando nuestra tecnología de Machine Learning. Podrás crear infinidad de carteras, modificando múltiples variables, que harán que cada cartera sea única para cada cliente, según sus necesidades y preferencias.",
    slider_ranges: {
      dont_like: "No me gusta",
      like_bit: "Me gusta poco",
      neutral: "Neutro",
      like: "Me gusta",
      very_like: "Me gusta mucho",
    },
    region_slider_ranges: {
      "Europe ++": "Europa ++",
      "Europe +" : "Europa +",
      "Neutral": "Neutral",
      "Usa +": "USA +",
      "Usa ++" : "USA ++",
    },
    size_slider_ranges: {
      "Pequeñas ++": "Pequeñas ++",
      "Pequeñas +": "Pequeñas +",
      "Neutral": "Neutral",
      "Grandes +": "Grandes +",
      "Grandes ++": "Grandes ++"
    },
    factor_slider_ranges: {
      "Value ++":"Value ++",
      "Value +": "Value +",
      "Neutral": "Neutral",
      "Growth +" : "Growth +",
      "Growth ++" : "Growth ++"  
    },
    risk_slider_ranges: {
      Defensivo: "Defensivo",
      Conservador: "Convervativo",
      Moderado: "Moderado",
      Equilibrado: "Equilibrado",
      Dinamico: "Dinámico",
      Agresivo: "Agresivo",
    },
    duration_slider_ranges : {
      "Corto ++" : "Corto ++",
      "Corto +":"Corto +",
      "Neutral": "Neutral",
      "Largo +":"Largo +",
      "Largo ++":"Largo ++"
    },
    ig_slider_ranges : {
      "High Yield ++":"High Yield ++",
      "High Yield +" :"High Yield +",
      "Neutral" :"Neutral",
      "Investment Grade +": "Investment Grade +",
      "Investment Grade ++" : "Investment Grade ++"
    },
    eqfi_slider_ranges : {
      "EQ ++":"Renta Variable ++",
      "EQ +" :"Renta Variable +",
      "Neutral" :"Neutral",
      "FI +": "Renta Fija +",
      "FI ++" : "FRenta Fija ++"
    },
    hedge_slider_ranges : {
      "Hedge":"Cubierto",
      "All" :"Todas",
      "Non Hedge" :"No Cubierto"
    },
    liquidity_slider_ranges : {
      "Low ++":"Baja ++",
      "Low +" :"Baja +",
      "Neutral" :"Neutral",
      "High +": "Alta +",
      "High ++" : "Alta ++"
    },

    liquidity:"Liquidez",
    hedge:"Cobertura de Divisa",
    EQ_FI:"Renta Variable/Fija",
    region:"Región",
    factor: "Factor",
    size:"Capitalización",
    risk:"Perfil",
    investmentGrade : "Investment Grade",
    duration: "Duración",
    north_america: "Norteamérica",
    emerging: "Emergentes",
    developed_europe: "Europa Desarrollada",
    price_benefit: "PER",
    concentrated_diversified: "Concentrado / Diversificado",
    invest_amount: "Importe a invertir",
    only_my_bank: "Mostrar fondos comprables en mi banco",
    limits: {
      "Equity/Bonds": "Acciones/Bonos",
      "Value/growth indicators": "Ratios",
      "Fixed Income Parameters": "Renta fija",
      equity_comp: "Composición RV",
      fi_comp: "Composición RF",
      Equity: "RV",
      Bonds: "RF",
      Sectors: "Sectores RV",
      consumer_cyclical: "Consumo cíclico",
      consumer_defensive: "Consumo defensivo",
      energy: "Energía",
      industrials: "Industriales",
      real_estate: "Inmobiliario",
      basics_materials: "Materias primas",
      healthcare: "Salud",
      financial_service: "Servicios financieros",
      utilities: "Utilities",
      communication_services: "Servicios de comunicación",
      technology: "Tecnología",
      per: "PER",
      p_value: "P/Valor contable",
      p_cf: "P/Cash flow",
      p_sales: "P/Ventas",
      dividend: "Dividendos",
      region: "Regiones RV",
      asia_developed: "Asia desarrollada",
      asia_emerging: "Asia emergente",
      australasia: "Australasia",
      europe_developed: "Europa desarrollada",
      europe_emerging: "Europa emergente",
      japan: "Japón",
      latin_america: "LATAM",
      north_america: "Norteamérica",
      uk: "Reino Unido",
      africa_middle_east: "África y Oriente Medio",
      estimated_duration: "Duración modificada",
      effective_duration: "Duración efectiva",
      inv_grade: "Investment grade",
      corp: "Corporativa",
      public: "Pública",
      securitised: "Titulizada",
    },
  },
  generate_portfolio_advanced: {
    text: "Crea las mejores carteras usando nuestra tecnología de Machine Learning. Podrás crear infinidad de carteras, modificando múltiples variables, que harán que cada cartera sea única para cada cliente, según sus necesidades y preferencias.",
    categories: "Categorías",
    max_weight: "Peso máximo por fondo",
    apply_risk: "Riesgo a aplicar",
    delete_weight_zero: "Elimina pesos 0",
    limit_weight_group: "Limita pesos por grupo",
    limit_compositions: "Limita composiciones",
    weights: "Pesos",
    compositions: "Composiciones",
    invest_amount: "Importe a invertir",
    invest_amount_placeholder: "0",
  },
  import_portfolio: {
    drag_file: "Arrastra el fichero de la cartera hasta aquí",
    only_csv: "Solo compatible con ficheros tipo .csv",
    browse_files: "Explora ordenador",
    import_portfolio: "Importa carteras",
    download_standard: "Descarga modelo Excel",
    file_uploaded: "El archivo %{filename} se cargó correctamente.",
    file_upload_failed: "Error al cargar el archivo %{filename}.",
    files_upload_error: "Ha ocurrido un error subiendo uno o varios archivos",
    balances: "Saldos",
    transactions: "Movimientos",
    new_true: "Sí",
    new_false: "No",
    is_new_portfolio: "¿Es una cartera nueva?",
    portfolio_update: "¿Es una actualización de cartera?",
    select_portfolio: "Selecciona cartera",
    portfolio_risks: "Nivel de riesgo de la cartera",
    include_benchmark: "Incluir Índice",
    portfolio_type: "Tipo de cartera",
    type_transactions: "Transacciones",
    optional: "Opcional",
    type_statement: "Posiciones",
    modal_ok: "De acuerdo",
    modal_cancel: "Cancelar"
  },
  portfolio_limits: {
    new_limit: "Crear Nuevas Preferencias",
    saved_limits: "Preferencias Guardadas",
    input_placeholder: "Nombre del límite",
    save_limit: "Guardar Limites",
    saved_limits_placeholder: "Límites guardados",
    save: "Guardar",
    open_limit: "Abrir",
    rename_limit: "Renombrar",
    delete_limit: "Borrar",
    confirm_delete: "¿Está seguro de que desea eliminar el límite seleccionado?",
    select_any_limit: "No se ha seleccionado ningún límite para eliminar.",
    name_already_exist: "El nombre ya existe.",
    should_user_limit: "El límite seleccionado no es tuyo.",
    group_limit_modify: "El límite del grupo no se puede modificar."
  },
  search_funds: {
    text: "Busca y analiza los mejores fondos. Aprovecha nuestras búsquedas predefinidas para que nuestra IA elija por ti la estrategia más conveniente en este momento. En tu cesta los puedes analizar y crear un pdf para ver los detalles de todos los fondos en conjunto.",
    search_asset: "Nombre o ISIN",
    funds_by_company: "Posiciones principales",
    search_by_company: "Por compañías",
    select_stored_search: "Selección de nuestra IA",
    select_filter_criteria: "Personalizada",
    add_full_list: "Añada lista completa",
    add_all: "Añada todos a la cesta",
    confirm_add_all: "¿Estás seguro de agregar todos los elementos sin guardar?",
    change_criteria: "Cambia los criterios",
    portfolio_funds: "Fondos en mis carteras",
    auto_filter: "Fondos comercializados en mi entidad",
    saved_search: "Búsquedas Guardadas",
    rename_search: "Guardadas Búsquedas",
    new_search: "Nueva Búsqueda",
    execute_search: "Ejecutar",
    edit_search: "Editar",
    save_search_error: "Error: Ya existe una búsqueda con ese nombre.",
    save_search_empty_name : "Error: Nombres vacíos no estan permitidos",
    funds_search_criteria: {
      foreign_exchange: "Divisas",
      cover: "Cubierta",
      morning_star: "Estrellas morningstar",
      patrimony_higher_than: "Patrimonio mayor o igual a:",
      million_e: "(millones €)",
      passive_only: "Solo fondos indexados",
      preferred_agencies_only: "Solo gestoras preferidas",
      preferred_funds_only: "Solo fondos preferidos",
      comparable_funds: "Fondos comercializados en mi entidad",
      base_currency_id: "Base currency",
      fees: "Fees",
      min_ini_purchase: "Min ini purchase",
      fund_size: "Fund size",
      share_class_size: "Share class size",
      distribution: "Distribución",
      years_since_launch: "Years since launch",
      risk: "Risk",
      ucits: "UCITS",
      legal_structure: "Legal structure",
      true: "Sí",
      false: "No",
      leverage: "Apalancado",
      inverse: "Inverso"
    },
    filter_criteria_category: {
      asset_type: "Tipo de activo",
      area: "Pais/Región",
      topics: "Temas",
      factor: "Factor",
      size: "Tamaño",
      fixed_income_category: "Categoría renta fija",
      term: "Plazo",
      mixed_assets_without_data: "Activos mixtos sin datos",
      mixed_assets: "Activos mixtos",
      alternatives_without_data: "Alternativos sin datos",
      alternatives: "Alternativos",
      country: "País/Región",
      sector: "Sector",
      fi_type: "Categoría renta fija",
      region: "País/Región",
      mix_type: "Tipos de mixtos",
      alternative_type: "Tipos de alternativos",
      assets_type: {
        Equity: "Renta Variable",
        "Money Market": "Mercado Monetario",
        Bond: "Renta Fija",
        "Mixed Assets": "Mixtos",
        Alternative: "Alternativos",
        Commodity: "Materias Primas"
      },
    },
    filter_criteria: {
      categorical_filters: "Buscar",
      percentage_filters: "Filtros por composición",
      reset_filters: "Reiniciar filtros",
      search: "Buscar",
      add_to_basket: "Añadir a cesta",
    },
    select_stored_options: {
      Alternative: "Alternativos",
      "Alternatives all EUR": "Todos los alternativos EUR",
      "Alternatives Cons and Moderate":
        "Alternativos conservadores y moderados",
      "Alternatives Dinamic and Agresive": "Alternativos dinámicos y agresivos",
      "Mixed Assets": "Activos mixtos",
      "Mixed assets EUR": "Activos mixtos EUR",
      "Mixed assets Cons and Moderate":
        "Activos mixtos conservadores y moderados",
      "Mixed assets dinamic and agresive":
        "Activos mixtos dinámicos y agresivos",
      "Money Market": "Mercado monetario",
      "Money Market USD": "Mercado monetario USD",
      "Fixed Income": "Renta fija",
      "Fixed income convertible EUR": "Renta fija convertible EUR",
      "Fixed income short term EUR": "Renta fija corto plazo EUR",
      "Fixed income short term USD": "Renta fija corto plazo USD",
      "Fixed income emerging global": "Renta fija emergentes global",
      "Fixed income emerging countries and regions":
        "Renta fija países y regiones emergentes",
      "Fixed income Europe EUR": "Renta fija Europa EUR",
      "Fixed income global EUR and HDG": "Renta fija global EUR y HDG",
      "Fixed income global USA": "Renta fija global USD",
      "Fixed income inflation EUR": "Renta fija inflación EUR",
      "Fixed income subordinates": "Renta fija subordinada",
      "Fixed income USA EUR": "Renta fija USA EUR",
      "Fixed income all": "Todo renta fija",
      Equity: "RV",
      "Equity Asia": "RV Asia",
      "Equity Emerging Markets global": "RV mercados emergentes global",
      "Equity global EUR": "RV global EUR",
      "Equity global USD": "RV global USD",
      "Equity Japan": "RV Japón",
      "Equity countries and smaller regions": "RV países y pequeñas regiones",
      "Equity sectors USD": "RV sectores USD",
      "Equity sectors": "RV sectores",
      "Equity USA": "RV USA",
      "Equity USA USD": "RV USA USD",
      "Equity all": "RV todos",
      "Commodities": "Materias Primas",
      "Equity emerging countries": "RV Países Emergentes",
      "Equity USA small": "RV USA Pequeñas Compañías",
      "Equity Europe Growth": "RV Europa Growth",
      "Equity USA Growth": "RV USA Growth",
      "Equity global Growth" : "RV Global Growth",
      'Money market EUR': 'Mercado monetario EUR',
      'Fixed income corporate': 'RF Corporativa',
      'Fixed income global EUR': 'RF Global ',
      'Fixed income government global and regional investment grade': 'RF Gobierno global y regional',
      'Fixed income high yield': 'RF Alto rendimiento',
      'Equity Europe': 'RV Europa',
      'Equity Europe small': 'RV Europa Pequeña Capitalización',
      'Equity global small': 'RV Global Pequeña Capitalización',
      'Equity USA EUR Hdg': 'RV USA HDG ',
      'Equity global EUR Hdg': 'RV Global HDG',
      'Equity USA growth EUR': 'RV USA crecimiento',
      'Equity Europe growth': 'RV Europa crecimiento',
      'Equity global growth EUR': 'RV Global crecimiento ',
      'Equity USA small EUR': 'RV USA Pequeña Capitalización',
      'Equity USA EUR': 'RV USA ',
      'Fixed income short term hedge': 'RF Corto plazo HDG',
      'Fixed income Europe': 'RF Europa',
      'Fixed income USA': 'RF USA',
      'Fixed income global hedge': 'RF Global HDG',
      'Fixed income corporate hedge': 'RF Corporativa HDG',
      'Fixed income government global and regional investment grade hedge': 'RF Gobierno global y regional HDG',
      'Fixed income USA hedge': 'RF USA HDG',
      'Fixed income high yield hedge': 'RF High Yield HDG',
      'Equity global value': 'RV Global valor',
      'Equity global big': 'RV Global Gran Capitalización',
      'Equity USA big': 'RV USA Gran Capitalización',
      'Equity USA value': 'RV USA valor',
      'Equity Europe value': 'RV Europa valor',
      'Equity Europe big': 'RV Europa Gran Capitalización'
    },
    percent_filter: {
      "Equity/Bonds": "Acciones/Bonos",
      Sectors: "Sectores",
      "Value/growth indicators": "Indicadores Value/growth",
      region: "Región",
      "Fixed Income Parameters": "Parámetros de renta fija",
      equity_comp: "Composición RV",
      fi_comp: "Composición RF",
      consumer_cyclical: "Consumo cíclico",
      consumer_defensive: "Consumo defensivo",
      energy: "Energía",
      industrials: "Industria",
      real_estate: "Inmobiliaria",
      basics_materials: "Materias primas",
      healthcare: "Salud",
      financial_service: "Servicios financieros",
      utilities: "Utilities",
      communication_services: "Telecomunicación",
      technology: "Tecnología",
      per: "PER",
      p_value: "P/Valor contable",
      p_cf: "P/Cash flow",
      p_sales: "P/Ventas",
      dividend: "Dividendos",
      asia_developed: "Asia desarrollada",
      asia_emerging: "Asia emergente",
      australasia: "Australasia",
      europe_developed: "Europa desarrollada",
      europe_emerging: "Europa emergente",
      japan: "Japón",
      latin_america: "Latinoamérica",
      north_america: "Norteamérica",
      uk: "Reino Unido",
      africa_middle_east: "África y Oriente Medio",
      estimated_duration: "Duración modificada",
      inv_grade: "Investment grade",
      corp: "Corporativa",
      public: "Pública",
      securitised: "Titulizada",
    },
    select_family_columns: {
      fees: "Comisiones",
      currency: "Divisa",
      hedge: "Cubierta",
      base_currency: "Divisa base",
      min_ini_purchase: "Inversión mínima",
      distribution: "Distribución",
      isin: "ISIN",
      name: "Nombre",
      tradable: "Comprable",
      yes: "Sí",
      no: "No",
    },
  },
  portfolio_funds_modal: {
    placeholder: "Seleccionar opción",
    all: "Todo",
    add_all: "Añadir todo a la cesta",
  },
  search_stocks: {
    text: "",
    search_asset: "Nombre o ISIN",
    select_filter_criteria: "Personalizada",
    filters: {
      "Cap(M)": "Mkt Cap",
      "P/ER(%)": "PER",
      "Yield(%)": "Rent. dividendo",
    },
  },
  search_cryptos: {
    text: "",
    search_asset: "Buscar Cryptos",
    asset_dropdown_placeholder: "Selecciona una Crypto",
  },
  search_benchmarks: {
    text: "Busca los Índices que mejor se adapten a tú tipo de cartera, desde Índices estándar hasta Índices personalizados por perfil.",
    search_asset: "Nombre",
    search_assets: "Encuentra un Índice",
    asset_region: "Región",
    asset_category: "Categoría",
    asset_dropdown_placeholder: "Encuentra un Índice",
    asset_region_category: "Selecciona primero región y categoría",
  },
  search_pensions: {
    asset_region: "Región",
    pension_type: "Tipo",
    asset_dropdown_placeholder: "Encuentra un Pension",
    asset_region_category: "Selecciona primero región y categoría",
  },
  funds_printer: {
    risk_level: "Nivel de riesgo",
    new_candidates : "Nuevos Candidatos",
    max_vol: " o vol Max",
    optimize_portfolio: "Optimizar cartera",
    add_to_basket: "Añadir a cesta",
    add_to_basket_tooltip: "Mover los fondos seleccionados a la cesta.",
    add_to_portfolio: "Añadir a cartera",
    add_to_portfolio_tooltip: "Añadir fondos seleccionados a la cartera.",
    portfolio_report: "Informe de cartera",
    pdf_report: "Cartera",
    asset_report: "Activos",
    basket_report: "Informe de Activos",
    basket_report_tooltip: "Generar informe en PDF con los fondos seleccionados.",
    reports: "Informes",
    reports_tooltip: "Generar informes en PDF con la cartera o fondos.",
    create_graph: "Graficar",
    delete_selection: "Eliminar selección",
    delete_selection_tooltip: "Eliminar los elementos seleccionados de la tabla.",
    delete_all: "Eliminar todos",
    delete_all_tooltip: "Eliminar todos los elementos de la tabla.",
    delete_all_confirm: "¿Estás seguro de eliminar todo?",
    plot_values: "Resaltar fondo seleccionado en el gráfico",
    neutral: "Neutro",
    added_funds: "Activos añadidos a cesta",
    added_benchmarks: "Índices añadidos a cesta",
    added_cryptos: "Cryptos añadidas a cesta",
    added_pensions: "Pensiones añadidas a cesta",
    cannot_family: "Solo puedes buscar por la familia de fondos",
    weight_sum: "Suma",
    release: "Liberar",
    discard: "Descartar",
    select_all_switch_tooltip: "Seleccionar o anular la selección de todas."
  },
  message: {
    hello: "Hola",
    changes_applied: "Los cambios han sido aplicados correctamente",
    failed_pdf: "La generación de PDF falló.",
    ask_funds: "Por favor seleccione algún fondo.",
    too_many_assets: "Por favor seleccione menos de 20 activos para graficar.",
    generate_pdf_with_only_funds: "Generando PDF solo para los fondos.",
    unable_delete: "No se pueden eliminar posiciones.",
    unable_delete_some: "No se pueden eliminar algunas posiciones.",
  },
  welcome: {
    title: "Aporta claridad a cada decisión de inversión",
    market_snapshot: "Empieza a usar brokvisor",
    news: "Próximamente",
    text: "Utilice una de nuestras listas de acciones prefiltradas creadas por nuestro IA para encontrar su próxima mejor inversión. O busque y compare valores utilizando métricas integrales de valoración y rendimiento.",
    video_title: "Aprende a sacar lo mejor de Brokvisor con nuestros tutoriales"
  },
  sidebar: {
    portfolio_management: "Gestión de carteras",
    portfolio_generate: "Genera cartera",
    my_portfolio: "Mis carteras",
    portfolio_import: "Importa cartera",
    search_assets: "Búsqueda de activos",
    funds: "Fondos",
    stocks: "Acciones",
    cryptos: "Cryptos",
    benchmark_search: "Índices",
    etf_search: "ETFs",
    pensions: "Pensiones",
    go_basket: "Cesta",
    go_portfolio: "Cartera"
  },
  auth: {
    already_have_account: "¿Ya tienes una cuenta?",
    sign_in: "Iniciar sesión",
    dont_have_account: "¿No tienes una cuenta?",
    sign_up: "Registrate ahora",
    sign_in_to: "Iniciar sesión en",
    sign_up_to: "Registrate en",
    enter_your_data: "Ingrese sus datos a continuación",
    username: "Correo electrónico",
    password: "Contraseña",
    keep_me_logged: "Recordarme",
    cb_account: "Gestión de subscripción",
    purchase_credits: "Gestión de API KEY",
    api_keys: "Claves API",
    manage_keys: "Administra tus claves o crea nuevas",
    create_key: "Crear clave",
    forgot_password: "¿Olvidó su contraseña?",
    loading: "Cargando...",
    we_will_help_you: "Le ayudaremos a restablecerla y volver a la normalidad.",
    notice:
      "Si tiene una cuenta, le enviaremos un correo electrónico con un enlace de restablecimiento.",
    recover_password: "Recuperar contraseña",
    want_new_password: "Cambiar contraseña",
    api_operation: "Operación",
    api_cost: "Costo",
    new_password: "Nueva contraseña",
    repeat_new_password: "Repita la nueva contraseña",
    new_password_indications: "Escriba una nueva contraseña",
    change_password_action_button: "Cambiar mi contraseña",
    return_to: "Volver a ",
    profile_details: "Detalles de la cuenta",
    help_center: "soporte@brokvisor.com",
    copy_to_clipboard: "El correo electrónico de asistencia se ha copiado en el portapapeles",
    copy_token: "El token copiado al portapapeles.",
    credit_amount_to_purchase: "¿Cuántos créditos vas a comprar?",
    confirm_payment: "¿Quiere completar el pago?",
    log_out: "Cerrar sesión",
    name: "Nombre",
    lastname: "Apellidos",
    email: "Correo electrónico",
    address: "Dirección",
    country: "País",
    city: "Ciudad",
    state: "Provincia / Estado",
    zipcode: "Código Postal",
    phone: "Teléfono",
    invoice_city: "Ciudad de facturación",
    invoice_country: "Provincia de facturación",
    invoice_zip: "CP de facturación",
    invoice_address: "Dirección de facturación",
    vat_number: "CIF/NIF de facturación",
    bank: "Entidad comercializadora de fondos",
    edit_account: "Cambiar datos personales",
    save_changes: "Guardar cambios",
    broker: "Gestor",
    confirmed_purchase: "Tu compra esta confirmada puedes encontrar la factura en gestión de subscripción",
    required_field_error: "Este campo es requerido",
    login_error_title: "",
    login_error_text:
      "Error: correo o contraseña incorrecta, por favor inténtalo de nuevo",
    forgot_password_error: "Algo ha ido mal. Por favor, inténtalo de nuevo.",
    forgot_password_success:
      "Hemos enviado un correo electrónico con un enlace de restablecimiento. Por favor, compruebe su bandeja de entrada.",
    forgot_password_success_toast_title: "Contraseña cambiada",
    forgot_password_success_toast_message:
      "Su contraseña ha sido cambiada correctamente",
    rules: {
      new_password: "Por favor, escriba una nueva contraseña",
      repeat_new_password: "Por favor, repita su nueva contraseña",
      passwords_dont_match: "Las contraseñas no coinciden",
      email: "Por favor, escriba su dirección de correo electrónico",
      password: "Por favor, escriba su contraseña",
    },
  },
  portfolio_overview: {
    title: "Resumen de cartera",
    save_portfolio: "Guarda Cartera",
    save_portfolio_label: "Guarda en Base de Datos",
    clean_portfolio: "Limpiar pantalla",
    portfolio_name: "Nombre de la cartera",
    saved_portfolio_name: "Nombre para guardar",
    ok_order: "Orden generada",
    save_portfolio_message: "¿Está seguro de guardar la cartera?",
    clean_portfolio_message: "¿Está seguro de limpiar la cartera?",
    generate_order: "Generar orden",
    redirect_no_portfolio: "No hay ninguna cartera cargada",
    save_confirm: "¿Confirmar para actualizar?",
    and: " y ",
    composition_limits_with_non_funds: "Los límites de composición no son compatibles con activos distintos de los fondos.",
    columns: {
      EUR: "EUR",
      "Rent 1 sem": "Rent 1S",
      "Rent 2 sem": "Rent 2S",
      CASH: "Efectivo",
      portfolio: "Cartera",
      monetary: "Monetario",
      fixed_income: "Renta fija",
      variable_income: "Renta variable",
      other: "Otros",
      name: "Nombre",
      rent_ytd: "Rent YTD",
      rent_1m: "Rent 1M",
      rent_3m: "Rent 3M",
      rent_1a: "Rent 1A",
      rent_3a: "Rent 3A",
      vol_44: "Vol 2M",
      vol_260: "Vol 1A",
      vola_12m: "Vola 12M",
      sharpe_12m: "Sharpe 12M",
      mxdd_2m: "MaxDD 2M",
      mxdd_44: "MaxDD 2M",
      mxdd_260: "MaxDD 1A",
      YTD: "YTD EUR",
      "Rent a => 5/2023": "Rent a => 5/2023",
      "Rent 1m": "Rent 1M",
      "Rent 3m": "Rent 3M",
      "Rent 1A": "Rent 1A",
      "Vola 12m": "Vola 12M",
      "Sharpe 12m": "Sharpe 12M",
      "mxdd 2m": "MaxDD 2M",
      "Sortino 2m": "Sortino 2M",
      Sortino40: "Sortino 40D",
      Rent_2023: "Rent 2023",
      false: "No",
      true: "Sí",
      weight: "Peso",
      tooltips: {
        EUR: "EUR",
        "Rent 1 sem": "Yield 1W",
        "Rent 2 sem": "Yield 2W",
        CASH: "CASH",
        portfolio: "Portfolio",
        monetary: "Monetary",
        fixed_income: "Fixed Income",
        variable_income: "Equity",
        other: "Other",
        name: "Name",
        rent_ytd: "Yield YTD",
        rent_1m: "Yield 1M",
        rent_3m: "Yield 3M",
        rent_1a: "Yield 1Y",
        rent_3a: "Yield 3Y",
        vol_44: "Vol 2M",
        vol_260: "Vol 1Y",
        vola_12m: "Vol 12M",
        sharpe_12m: "Sharpe 12M",
        mxdd_2m: "MaxDD 2m",
        mxdd_44: "MaxDD 2M",
        mxdd_260: "MaxDD 1Y",
        YTD: "YTD EUR",
        "Rent a => 5/2023": "Yield to => 5/2023",
        "Rent 1m": "Yield 1M",
        "Rent 3m": "Yield 3M",
        "Rent 1A": "Yield 1Y",
        "Vola 12m": "Vol 12M",
        "Sharpe 12m": "Sharpe 12M",
        "mxdd 2m": "MaxDD 2M",
        Sortino40: "Sortino 40D",
        Rent_2023: "Yield 2023",  
      }
    },
    headings: {
      "Assets": "Distribución de Activos",
      chart_regions: "Regiones",
      "Asset Type": "Tipo de activo",
      Regions: "Regiones Renta Variable",
      Sectors: "Sectores Renta Variable",
      Size: "Tamaño",
      "Bond parameters": "Parámetros RF",
      Countries: "Paises",
      Ratings: "Ratings",
      Coupons: "Cupones",
      Maturity: "Vencimiento",
      positions: "Posiciones",
      ratios: "Ratios",
      "Fixed income sectors": "Sectores de renta fija",
      "Fixed income corp sectors": "Sectores corporativos de renta fija",
      "Fixed income regions": "Regiones de renta fija",
      summary: "Resumen",
      "Value/growth indicators": "Indicadores de valor/crecimiento",
      portfolio: "Carteras",
      assets: "Distribución de Activos",
      "Main positions": "Posiciones principales",
      "Asset Information": "Información de activos",
      "Asset compositions": "Composiciones de activos",
      "Ratios": "Ratios",
      "Returns": "Rentabilidades",
      "Risk": "Riesgo",
      "graph": "Gráfico"
    },
    invest_amount: "Importe a invertir",
    invest_amount_placeholder: "0",
    enter_portfolio_name: "Introduzca un nombre para la cartera",
  },
  portfolio_control: {
    description: "Puede revisar y reequilibrar todas sus carteras desde esta página.",
    rebalancer: "Rebalancear",
    review: "Abrir cartera",
    portfolio_table: "Tabla Carteras",
    graph_view: "Gráfico Comparativa",
    select_one_fund: "Seleccione solo una cartera.",
    select_any_fund: "Seleccione al menos una cartera.",
    color_legend: "Leyenda",
    benchmark_comparisons: "Comparación con el benchmark",
    "far below": "muy por debajo",
    below: "por debajo",
    similar: "similar",
    above: "por encima",
    "far above": "muy por encima"
  },
  operands: {
    no_limits: "Sin límites",
    greater_than: "(>) Mayor que",
    greater_equal_than: "(≥) Mayor/igual que",
    less_than: "(<) Menor que",
    less_equal_than: "(≤) Menor/igual que",
    equal_to: "(=) Igual a",
  },
  amount_units: {
    million: "Millón",
    thousand_million: "Mil Millones",
    billion: "Billón",
  },
  search_categories: {
    "EQ GLOB": "RV Global",
    "EQ USA": "RV USA",
    "EQ SECTORS": "RV Sectoriales",
    "EQ COUNTRIES": "RV Países",
    "EQ EUROPE": "RV Europa",
    "FI GLOB": "RF Global",
    "FI HY": "RF High Yield",
    "FI GOV": "RF Gobiernos",
    "FI EM": "RF Emergente",
    "FI SHORT EUR": "RF Corto EUR",
    "FI CORP": "RF Corp",
    "MM EUR": "Monetario EUR",
    "MIX DIN AGR": "Mixtos Agresivos",
    "MIX CON MOD": "Mixtos Conserv",
    "ALT DIN AGR": "Alternat Agresivos",
    "ALT CON MOD": "Alternat Conserv",
    "COMMODITIES": "Materias Primas",
    "EQ EM COUNTRIES": "RV Países Emergentes",
    "EQ EUROPE SMALL": "RV Europa Pequeñas Compañías",
    "EQ USA SMALL": "RV USA Pequeñas Compañías",
    "EQ EUROPE GROWTH": "RV Europa Growth",
    "EQ USA GROWTH": "RV USA Growth",
    "FI SUB": "RF Subordinada",
    "FI CONV": "RF Convertibles",
    "FI INFLAT": "RF Ligados a la Inflación",
    "FI EUROPE": "RF Europa",
    "FI EUROPE EX-HY": "RF Europa Sin High Yield", 
    "FI USA EX-HY": "RF USA Sin High Yield",
    "FI GLOB EUR H": "RF Global EUR Cubierto", 
    "EQ ASIA": "RV Asia",
    "EQ EM": "RV Mercados Emergentes", 
    "EQ JAPAN": "RV Japón" 
  },
  search_category_tooltips: {
    "EQ GLOB": "Fondos globales de renta variable",
    "EQ USA": "Fondos de renta variable USA",
    "EQ SECTORS": "Fondos sectoriales de renta variable",
    "EQ COUNTRIES": "Fondos de renta variable de países y algunas regiones de menor relevancia",
    "EQ EUROPE": "Fondos de renta variable europea incluida zona euro",
    "FI GLOB": "Fondos de renta fija global",
    "FI HY": "Fondos de High Yield globales y regionales",
    "FI GOV": "Fondos de renta fija gobiernos",
    "FI EM": "Fondos de renta fija emergente",
    "FI SHORT EUR": "Fondos de renta fija corto plazo en euros",
    "FI CORP": "Fondos de renta fija corporativa tanto globales como regionales",
    "MM EUR": "Fondos mercado monetario",
    "MIX DIN AGR": "Fondos mixtos de perfil dinámico y agresivo",
    "MIX CON MOD": "Fondos mixtos de perfil conservador y moderado",
    "ALT DIN AGR": "Fondos alternativos de perfil dinámico y agresivo",
    "ALT CON MOD": "Fondos alternativos de perfil conservador y moderado",
    "EQ EUROPE SMALL": "Fondos de renta variable europea de pequeña capitalización",
    "EQ USA SMALL": "Fondos de renta variable en USA de pequeña capitalización",
    "EQ EUROPE GROWTH": "Fondos de renta variable europea enfocados en crecimiento",
    "EQ USA GROWTH": "Fondos de renta variable americana enfocados en crecimiento",
    "FI SUB": "Fondos de renta fija subordinada",
    "FI EUROPE": "Fondos de renta fija europea",
    "EQ EM COUNTRIES": "Fondos de renta variable de mercados emergentes de países específicos",
    "COMMODITIES": "Fondos de materias primas",
    "FI CONV": "Fondos de renta fija convertibles",
    "FI INFLAT": "Fondos de renta fija  Ligados a la Inflación",
    "FI EUROPE EX-HY": "Fondos de renta fija Europa Sin High Yield", 
    "FI USA EX-HY": "Fondos de renta fija USA Sin High Yield",
    "FI GLOB EUR H": "Fondos de renta fija Global EUR Cubierto", 
    "EQ ASIA": "Fondos de renta variable Asia",
    "EQ EM": "Fondos de renta variable Mercados Emergentes", 
    "EQ JAPAN": "Fondos de renta variable Japón" 
  },
  header_tooltips: {
    currency: 'Divisa clase del fondo',
    fi_comp: '% de renta fija que tiene el fondo',
    ret_1: "Rentabilidad 1 día en EUR",
    ret_5: "Rentabilidad 1 semana en EUR",
    ret_10: "Rentabilidad 2 semanas en EUR",
    ret_22: "Rentabilidad 1 mes en EUR",
    ret_44: "Rentabilidad 2 meses en EUR",
    ret_65: "Rentabilidad 3 meses en EUR",
    ret_130: "Rentabilidad 6 meses en EUR",
    ret_260: "Rentabilidad 1 año en EUR",
    ret_780: "Rentabilidad 3 año en EUR",
    ret_1300: "Rentabilidad 5 año en EUR",
    ret_2021: "Rentabilidad 2021 en EUR",
    ret_2022: "Rentabilidad 2022 en EUR",
    ret_2023: "Rentabilidad 2023 en EUR",
    vol_5: "Volatilidad 1 semana en EUR",
    vol_10: "Volatilidad 2 semanas en EUR",
    vol_22: "Volatilidad 1 mes en EUR",
    vol_44: "Volatilidad 2 meses en EUR",
    vol_65: "Volatilidad 3 meses en EUR",
    vol_260: "Volatilidad 1 año en EUR",
    vol_780: "Volatilidad 3 años en EUR",
    vol_1300: "Volatilidad 5 años en EUR",
    vol_2021: "Volatilidad 2021 en EUR",
    vol_2022: "Volatilidad 2022 en EUR",
    vol_2023: "Volatilidad 2023 en EUR",
    mxdd_5: "Max Drawdown 1 semana",
    mxdd_10: "Max Drawdown 2 semanas",
    mxdd_22: "Max Drawdown 1 mes",
    mxdd_44: "Max Drawdown 2 meses",
    mxdd_65: "Max Drawdown 3 meses",
    mxdd_260: "Max Drawdown 1 año",
    mxdd_780: "Max Drawdown 3 años",
    mxdd_1300: "Max Drawdown 5 años",
    mxdd_2021: "Max Drawdown 2021",
    mxdd_2022: "Max Drawdown 2022",
    mxdd_2023: "Max Drawdown 2023",
    equity_comp: "% de renta variable que tiene el fondo",
    fund_type: "Categoría del fondo",
    YTLMC: "Cierre del último mes",
    name: "Nombre del fondo",
    hedge : "Clase con cobertura de divisa",
    distribution : "Clase que distribuye dividendos",
    fund_size: "Volumen total del fondo",
    isin_tkr: "Código de identificación",
    family:"Número de brokvisor para identificar todas las clases de un mismo fondo",
    launch_date: "Fecha lanzamiento del fondo",
    pasive: "Fondo de gestión indexada",
    p_value: "Precio/Valor contable",
    p_cf: "Precio/Cash flow",
    p_sales: "Precio/Ventas",
    per: "Precio/Beneficio",
    corp: "Deuda corporativa",
    public: "Deuda pública",
    securitised: "Deuda titulizada",
    years_since_launch : "Años desde lanzamiento del fondo",
    size_cat: "Tamaño medio de las compañías del fondo",
    factor_cat: "Estilo de inversión del fondo",
    term_cat: "Plazo de la renta fija"
  },
  positions_fields: {
    isin_tkr: "ISIN/TKR",
    name: "Nombre",
    family: "Familia",
    currency: "Divisa",
    fund_type: "Tipo de fondo",
    inverse: "Inverso",
    leverage: "Apalancado",
    hedge: "Cubierta",
    country: "País",
    region: "Región",
    morningstar_category: "Categoría Morningstar",
    sector: "Sector",
    pasive: "Pasivo",
    size_cat: "Tamaño",
    factor_cat: "Factor",
    term_cat: "Plazo",
    nan: "nan",
    min_ini_purchase: "Inversión mínima",
    min_ini_purchase_eur: "Inversión mínima en EUR",
    shares_cash: "Efectivo",
    market_cap: "Capitalización bursátil",
    fund_size: "Tamaño del fondo",
    fees: "Comisiones",
    distribution: "Distribución",
    launch_date: "Fecha de lanzamiento",
    rat_mstar: "Estrellas Morningstar",
    error_download: "Error descarga",
    closed: "Cerrado",
    clean_class: "Clase limpia",
    ideas: "Ideas",
    asset_manager_id: "Id del gestor de activos",
    equity_comp: "Composición RV",
    fi_comp: "Composición RF",
    per: "PER",
    p_value: "P/Valor contable",
    p_cf: "P/Cash flow",
    p_sales: "P/Ventas",
    dividend: "Dividendos",
    consumer_cyclical: "Consumo cíclico",
    consumer_defensive: "Consumo defensivo",
    energy: "Energía",
    industrials: "Industria",
    real_estate: "Inmobiliario",
    basics_materials: "Materias primas",
    healthcare: "Salud",
    financial_service: "Servicios financieros",
    utilities: "Utilities",
    communication_services: "Servicios de comunicación",
    technology: "Tecnología",
    asia_developed: "Asia desarrollada",
    asia_emerging: "Asia emergente",
    australasia: "Australasia",
    europe_developed: "Europa desarrollada",
    europe_emerging: "Europa emergente",
    japan: "Japón",
    latin_america: "LATAM",
    north_america: "Norteamérica",
    uk: "Reino Unido",
    africa_middle_east: "África medio este",
    estimated_duration: "Duración modificada",
    effective_duration: "Duración efectiva",
    years_since_launch: "Años desde lanzamiento",
    inv_grade: "Investment grade",
    corp: "Corporativa",
    public: "Pública",
    securitised: "Titulizada",
    fund_manager: "Gestor del fondo",
    last_value_date: "Fecha del último valor",
    base_currency: "Divisa base",
    avg_volume_10: "Volumen medio 10D",
    last_mkt_value: "Último valor de mercado",
    last_market_value : "Precio de Mercado", 
    revenue: "Ingresos",
    eps: "BPA",
    shares_outstanding: "Acciones en circulación",
    yield: "Yield",
    next_earning_date: "Próxima fecha de ganancias",
    inf: "Descripción",
    industry: "Industria",
    other_type: "Otro tipo",
    group: "Grupo",
    max_weight: "Peso máximo",
    min_weight: "Peso mínimo",
    YTD: "YTD EUR",
    "Rent a => 5/2023": "Rent a => 5/2023",
    "Rent 1m": "Rent 1M",
    "Rent 3m": "Rent 3M",
    "Rent 1A": "Rent 1A",
    "Vola 12m": "Vola 12M",
    "Sharpe 12m": "Sharpe 12M",
    "Sortino 2m": "Sortino 2M",
    Sortino40: "Sortino 40D",
    Rent_2023: "Rent 2023",
    r_ini: "R Ini",
    titles: "Títulos",
    invested: "Inversión",
    market_value: "Valor del Mercado",
    search: "Búsqueda",
    unrealized_gains: "Ganancias no Obtenidas",
    weight: "Peso",
    port: "Port",
    port_: "Port ",
    port_1: "Port 1",
    ret_1: "Rent 1D",
    ret_5: "Rent 1S",
    ret_10: "Rent 2S",
    ret_22: "Rent 1M",
    ret_44: "Rent 2M",
    ret_65: "Rent 3M",
    ret_130: "Rent 6M",
    ret_260: "Rent 1A",
    ret_780: "Rent 3A",
    ret_1300: "Rent 5A",
    ret_2021: "Rent 2021",
    ret_2022: "Rent 2022",
    ret_2023: "Rent 2023",
    vol_5: "Vol 1S",
    vol_10: "Vol 2S",
    vol_22: "Vol 1M",
    vol_44: "Vol 2M",
    vol_65: "Vol 3M",
    vol_260: "Vol 1A",
    vol_780: "Vol 3A",
    vol_1300: "Vol 5A",
    vol_2021: "Vol 2021",
    vol_2022: "Vol 2022",
    vol_2023: "Vol 2023",
    mxdd_5: "MaxDD 1S",
    mxdd_10: "MaxDD 2S",
    mxdd_22: "MaxDD 1M",
    mxdd_44: "MaxDD 2M",
    mxdd_65: "MaxDD 3M",
    mxdd_260: "MaxDD 1A",
    mxdd_780: "MaxDD 3A",
    mxdd_1300: "MaxDD 5A",
    mxdd_2021: "MaxDD 2021",
    mxdd_2022: "MaxDD 2022",
    mxdd_2023: "MaxDD 2023",
    "mxdd 2m": "MaxDD 2M",
    sortino_44: "Sortino 44",
    sh_44: "Sharpe 44",
    tradable: "Comprable",
    Cash: "Efectivo",
    Convertible: "Convertible",
    Equity: "Renta Variable",
    "Fixed Income": "Renta Fija",
    Others: "Otros",
    Preferred: "Preferentes",
    "non_equity": "No RV",
    "Effective Duration": "Duración efectiva",
    "Modified Duration": "Duración modificada",
    "Effective Maturity": "Maturity efectiva",
    "Basic Materials": "Materiales básicos",
    "Communication Services": "Servicios de comunicación",
    "Consumer Cyclical": "Consumo cíclico",
    "Consumer Defensive": "Consumo defensivo",
    Energy: "Energía",
    Financial: "Finanzas",
    Healthcare: "Sanidad",
    Industrials: "Industria",
    "Information Technology": "Tecnologías",
    "Real Estate": "Bienes raíces",
    "Utilities": "Utilities",
    Unclassified: "Sin Clasificar",
    Unkown: "Desconocido",
    'Lower than B': "Inferior a B",
    position_name: "Nombre Activo",
    Total: "Total",
    Argentina: 'Argentina',
    Asia: 'Asia',
    Australia: 'Australia',
    Austria: 'Austria',
    Belgium: 'Bélgica',
    Brazil: 'Brasil',
    BRIC: 'BRIC',
    Canada: 'Canadá',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colombia',
    'Czech Republic': 'República Checa',
    Denmark: 'Dinamarca',
    'Eastern Europe': 'Europa del Este',
    EMEA: 'EMEA',
    'Euro zone': 'Zona Euro',
    Europe: 'Europa',
    Finland: 'Finlandia',
    France: 'Francia',
    Germany: 'Alemania',
    Greece: 'Grecia',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungría',
    India: 'India',
    Indonesia: 'Indonesia',
    Ireland: 'Irlanda',
    Israel: 'Israel',
    Italy: 'Italia',
    Malaysia: 'Malasia',
    MEA: 'MEA',
    Mexico: 'México',
    Netherlands: 'Países Bajos',
    'New Zealand': 'Nueva Zelanda',
    Nordic: 'Países Nórdicos',
    Norway: 'Noruega',
    'Nueva Zelanda': 'Nueva Zelanda',
    Pakistan: 'Pakistán',
    Peru: 'Perú',
    Philippines: 'Filipinas',
    Poland: 'Polonia',
    Portugal: 'Portugal',
    Russia: 'Rusia',
    Singapore: 'Singapur',
    'South Africa': 'Sudáfrica',
    'South Korea': 'Corea del Sur',
    Spain: 'España',
    Sweden: 'Suecia',
    Switzerland: 'Suiza',
    Taiwan: 'Taiwán',
    Thailand: 'Tailandia',
    Turkey: 'Turquía',
    'United States': 'Estados Unidos',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    'US Equity': 'US Equity',
    'Africa/Middle East': 'África/Medio Oriente',
    'Asia Developed': 'Asia desarrollada',
    'Asia Emerging': 'Asia emergente',
    Australasia: 'Australasia',
    'Emerging Europe': 'Europa emergente',
    'Emerging markets': 'Mercados emergentes',
    'Europe Developed': 'Europa desarrollada',
    Global: 'Global',
    Japan: 'Japón',
    'Latin America': 'América Latina',
    'North America': 'América del norte',
    'United Kingdom': 'Reino Unido',
    'Africa MidEast': 'África Medio Oriente',
    'Asia Dev': 'Asia Desarrollada',
    'Asia Emer': 'Asia Emerergente',
    'Emer Europe': 'Europa emergente',
    'Austral Asia': 'Australasia',
    'Emer Markets': 'Mercados Emergentes',
    'Europe Dev': 'Europa Desarrollada',
    Latam: 'Latinoamérica',
    UK: 'Reino Unido',
    Biotech: 'Biotecnología',
    Commodities: 'Commodities',
    created_at: 'Creado en',
    updated_at: 'Actualizado en',
    risk: 'Riesgo',
    tooltips: {
      Cash: "Efectivo",
      Convertible: "Convertible",
      Equity: "Renta Variable",
      "Fixed Income": "Renta Fija",
      Others: "Otros",
      Preferred: "Preferentes",
    }
  },
  countries: {
    Argentina: 'Argentina',
    Asia: 'Asia',
    Australia: 'Australia',
    Austria: 'Austria',
    Belgium: 'Bélgica',
    Brazil: 'Brasil',
    BRIC: 'BRIC',
    Canada: 'Canadá',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colombia',
    'Czech Republic': 'República Checa',
    Denmark: 'Dinamarca',
    'Eastern Europe': 'Europa del Este',
    EMEA: 'EMEA',
    'Euro zone': 'Zona Euro',
    Europe: 'Europa',
    Finland: 'Finlandia',
    France: 'Francia',
    Germany: 'Alemania',
    Greece: 'Grecia',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungría',
    India: 'India',
    Indonesia: 'Indonesia',
    Ireland: 'Irlanda',
    Israel: 'Israel',
    Italy: 'Italia',
    Malaysia: 'Malasia',
    MEA: 'MEA',
    Mexico: 'México',
    Netherlands: 'Países Bajos',
    'New Zealand': 'Nueva Zelanda',
    Nordic: 'Países Nórdicos',
    Norway: 'Noruega',
    'Nueva Zelanda': 'Nueva Zelanda',
    Pakistan: 'Pakistán',
    Peru: 'Perú',
    Philippines: 'Filipinas',
    Poland: 'Polonia',
    Portugal: 'Portugal',
    Russia: 'Rusia',
    Singapore: 'Singapur',
    'South Africa': 'Sudáfrica',
    'South Korea': 'Corea del Sur',
    Spain: 'España',
    Sweden: 'Suecia',
    Switzerland: 'Suiza',
    Taiwan: 'Taiwán',
    Thailand: 'Tailandia',
    Turkey: 'Turquía',
    'United States': 'Estados Unidos',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    'US Equity': 'US Equity',
    'Africa/Middle East': 'África/Medio Oriente',
    'Asia Developed': 'Asia desarrollada',
    'Asia Emerging': 'Asia emergente',
    Australasia: 'Australasia',
    'Emerging Europe': 'Europa emergente',
    'Emerging markets': 'Mercados emergentes',
    'Europe Developed': 'Europa desarrollada',
    Global: 'Global',
    Japan: 'Japón',
    'Latin America': 'América Latina',
    'North America': 'América del norte',
    'United Kingdom': 'Reino Unido',
    'Africa MidEast': 'África Medio Oriente',
    'Asia Dev': 'Asia Desarrollada',
    'Asia Emer': 'Asia Emerergente',
    'Emer Europe': 'Europa emergente',
    'Austral Asia': 'Australasia',
    'Emer Markets': 'Mercados Emergentes',
    'Europe Dev': 'Europa Desarrollada',
    Latam: 'Latinoamérica',
    UK: 'Reino Unido',
  },
  regions: {
    Argentina: 'Argentina',
    Asia: 'Asia',
    Australia: 'Australia',
    Austria: 'Austria',
    Belgium: 'Bélgica',
    Brazil: 'Brasil',
    BRIC: 'BRIC',
    Canada: 'Canadá',
    Chile: 'Chile',
    China: 'China',
    Colombia: 'Colombia',
    'Czech Republic': 'República Checa',
    Denmark: 'Dinamarca',
    'Eastern Europe': 'Europa del Este',
    EMEA: 'EMEA',
    'Euro zone': 'Zona Euro',
    Europe: 'Europa',
    Finland: 'Finlandia',
    France: 'Francia',
    Germany: 'Alemania',
    Greece: 'Grecia',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hungría',
    India: 'India',
    Indonesia: 'Indonesia',
    Ireland: 'Irlanda',
    Israel: 'Israel',
    Italy: 'Italia',
    Malaysia: 'Malasia',
    MEA: 'MEA',
    Mexico: 'México',
    Netherlands: 'Países Bajos',
    'New Zealand': 'Nueva Zelanda',
    Nordic: 'Países Nórdicos',
    Norway: 'Noruega',
    'Nueva Zelanda': 'Nueva Zelanda',
    Pakistan: 'Pakistán',
    Peru: 'Perú',
    Philippines: 'Filipinas',
    Poland: 'Polonia',
    Portugal: 'Portugal',
    Russia: 'Rusia',
    Singapore: 'Singapur',
    'South Africa': 'Sudáfrica',
    'South Korea': 'Corea del Sur',
    Spain: 'España',
    Sweden: 'Suecia',
    Switzerland: 'Suiza',
    Taiwan: 'Taiwán',
    Thailand: 'Tailandia',
    Turkey: 'Turquía',
    'United States': 'Estados Unidos',
    Venezuela: 'Venezuela',
    Vietnam: 'Vietnam',
    'US Equity': 'US Equity',
    'Africa/Middle East': 'África/Medio Oriente',
    'Asia Developed': 'Asia desarrollada',
    'Asia Emerging': 'Asia emergente',
    Australasia: 'Australasia',
    'Emerging Europe': 'Europa emergente',
    'Emerging markets': 'Mercados emergentes',
    'Europe Developed': 'Europa desarrollada',
    Global: 'Global',
    Japan: 'Japón',
    'Latin America': 'América Latina',
    'North America': 'América del norte',
    'United Kingdom': 'Reino Unido',
    'Africa MidEast': 'África Medio Oriente',
    'Asia Dev': 'Asia Desarrollada',
    'Asia Emer': 'Asia Emerergente',
    'Emer Europe': 'Europa emergente',
    'Austral Asia': 'Australasia',
    'Emer Markets': 'Mercados Emergentes',
    'Europe Dev': 'Europa Desarrollada',
    Latam: 'Latinoamérica',
    UK: 'Reino Unido',
  },
  sectors: {
    "Basic Materials": "Materiales básicos",
    Biotech: "Biotecnología",
    Commodities: "Commodities",
    "Communication Services": "Servicios de comunicación",
    Consumer: "Consumo",
    "Consumer Cyclical": "Consumo cíclico",
    "Consumer Defensive": "Consumo defensivo",
    Energy: "Energía",
    Environment: "Medio ambiente",
    Financial: "Finanzas",
    Gold: "Oro",
    Healthcare: "Sanidad",
    Industrials: "Industriales",
    "Information Technology": "Tecnología",
    Infrastructure: "Infraestructura",
    Megatrends: "Megatendencias",
    Mobility: "Movilidad",
    "Natural resources": "Recursos naturales",
    "Real Estate": "Bienes raíces",
    Utilities: "Servicios",
  },
  sizes: {
    Small: "Small Cap",
    "Mid/Small": "Small/Mid Cap",
    Mid: "Mid Cap",
    Flex: "Flexible Cap",
    Big: "Big Cap",
  },
  factors: {
    Growth: "Growth",
    Blend: "Blend",
    Value: "Value",
    "High dividend": "High dividend"
  },
  altTypes: {
    "Alt conservative": "Alternativos conservadores",
    "Alt moderate": "Alternativos moderados",
    "Alt dynamic": "Alternativos dinámicos",
    "Alt aggressive": "Alternativos agresivos",
    Inverse: "Inverso",
    "Fixed income inverse": "Renta fija inversa",
    Guaranteed: "Garantizados",
  },
  mixTypes: {
    "Mix moderate": "Mixtos moderados",
    "Mixed conservative": "Mixtos conservadores",
    "Mix dynamic": "Mixtos dinámicos",
    "Mixed aggressive": "Mixtos agresivos",
  },
  termCats: {
    "Ultra short": "Ultra short",
    Short: "Short",
    Mid: "Mid",
    Flexible: "Flexible",
    Long: "Long",
  },
  rentasRF: {
    "Money Market": "Mercados Monetarios",
    Government: "Gobiernos",
    "High yield": "High yield",
    Convertible: "Convertible",
    Corporate: "Corporativos",
    "Inflation-Linked": "Linkados a Inflación",
    Subordinated: "Subordinados",
    Diversified: "Diversificados",
  },
  industries: {
    "Advertising Agencies": "Advertising Agencies",
    "Aerospace & Defense": "Aerospace & Defense",
    "Agricultural Inputs": "Agricultural Inputs",
    "Airlines": "Airlines",
    "Airports & Air Services": "Airports & Air Services",
    "Aluminum": "Aluminum",
    "Apparel Manufacturing": "Apparel Manufacturing",
    "Apparel Retail": "Apparel Retail",
    "Asset Management": "Asset Management",
    "Auto & Truck Dealerships": "Auto & Truck Dealerships",
    "Auto Manufacturers": "Auto Manufacturers",
    "Auto Parts": "Auto Parts",
    "Automobiles & Auto Parts": "Automobiles & Auto Parts",
    "Banks - Diversified": "Banks - Diversified",
    "Banks - Regional": "Banks - Regional",
    "Beverages - Brewers": "Beverages - Brewers",
    "Beverages - Non-Alcoholic": "Beverages - Non-Alcoholic",
    "Beverages - Wineries & Distilleries": "Beverages - Wineries & Distilleries",
    "Biotechnology": "Biotechnology",
    "Broadcasting": "Broadcasting",
    "Building Materials": "Building Materials",
    "Building Products & Equipment": "Building Products & Equipment",
    "Business Equipment & Supplies": "Business Equipment & Supplies",
    "Capital Markets": "Capital Markets",
    "Chemicals": "Chemicals",
    "Communication Equipment": "Communication Equipment",
    "Communications & Networking": "Communications & Networking",
    "Computer Hardware": "Computer Hardware",
    "Confectioners": "Confectioners",
    "Conglomerates": "Conglomerates",
    "Construction & Engineering": "Construction & Engineering",
    "Consulting Services": "Consulting Services",
    "Consumer Electronics": "Consumer Electronics",
    "Copper": "Copper",
    "Credit Services": "Credit Services",
    "Department Stores": "Department Stores",
    "Diagnostics & Research": "Diagnostics & Research",
    "Discount Stores": "Discount Stores",
    "Drug Manufacturers - General": "Drug Manufacturers - General",
    "Drug Manufacturers - Specialty & Generic": "Drug Manufacturers - Specialty & Generic",
    "Education & Training Services": "Education & Training Services",
    "Electrical Equipment & Parts": "Electrical Equipment & Parts",
    "Electrical Utilities & IPPs": "Electrical Utilities & IPPs",
    "Electronic Components": "Electronic Components",
    "Electronic Gaming & Multimedia": "Electronic Gaming & Multimedia",
    "Electronics & Computer Distribution": "Electronics & Computer Distribution",
    "Engineering & Construction": "Engineering & Construction",
    "Entertainment": "Entertainment",
    "Farm & Heavy Construction Machinery": "Farm & Heavy Construction Machinery",
    "Farm Products": "Farm Products",
    "Financial Conglomerates": "Financial Conglomerates",
    "Financial Data & Stock Exchanges": "Financial Data & Stock Exchanges",
    "Food Distribution": "Food Distribution",
    "Footwear & Accessories": "Footwear & Accessories",
    "Furnishings, Fixtures & Appliances": "Furnishings, Fixtures & Appliances",
    "Gambling": "Gambling",
    "Gold": "Gold",
    "Grocery Stores": "Grocery Stores",
    "Health Information Services": "Health Information Services",
    "Healthcare Plans": "Healthcare Plans",
    "Home Improvement Retail": "Home Improvement Retail",
    "Household & Personal Products": "Household & Personal Products",
    "Industrial Distribution": "Industrial Distribution",
    "Information Technology Services": "Information Technology Services",
    "Infrastructure Operations": "Infrastructure Operations",
    "Insurance": "Insurance",
    "Insurance - Diversified": "Insurance - Diversified",
    "Insurance - Life": "Insurance - Life",
    "Insurance - Property & Casualty": "Insurance - Property & Casualty",
    "Insurance - Reinsurance": "Insurance - Reinsurance",
    "Insurance - Specialty": "Insurance - Specialty",
    "Insurance Brokers": "Insurance Brokers",
    "Integrated Freight & Logistics": "Integrated Freight & Logistics",
    "Internet Content & Information": "Internet Content & Information",
    "Internet Retail": "Internet Retail",
    "Investment Holding Companies": "Investment Holding Companies",
    "Leisure": "Leisure",
    "Lodging": "Lodging",
    "Lumber & Wood Production": "Lumber & Wood Production",
    "Luxury Goods": "Luxury Goods",
    "Machinery, Tools, Heavy Vehicles, Trains & Ships": "Machinery, Tools, Heavy Vehicles, Trains & Ships",
    "Marine Shipping": "Marine Shipping",
    "Medical Care Facilities": "Medical Care Facilities",
    "Medical Devices": "Medical Devices",
    "Medical Distribution": "Medical Distribution",
    "Medical Instruments & Supplies": "Medical Instruments & Supplies",
    "Metal Fabrication": "Metal Fabrication",
    "Mortgage Finance": "Mortgage Finance",
    "Oil & Gas Drilling": "Oil & Gas Drilling",
    "Oil & Gas E&P": "Oil & Gas E&P",
    "Oil & Gas Equipment & Services": "Oil & Gas Equipment & Services",
    "Oil & Gas Integrated": "Oil & Gas Integrated",
    "Oil & Gas Midstream": "Oil & Gas Midstream",
    "Oil & Gas Refining & Marketing": "Oil & Gas Refining & Marketing",
    "Other": "Other",
    "Other Industrial Metals & Mining": "Other Industrial Metals & Mining",
    "Other Precious Metals & Mining": "Other Precious Metals & Mining",
    "Packaged Foods": "Packaged Foods",
    "Packaging & Containers": "Packaging & Containers",
    "Paper & Paper Products": "Paper & Paper Products",
    "Personal Services": "Personal Services",
    "Pharmaceutical Retailers": "Pharmaceutical Retailers",
    "Pollution & Treatment Controls": "Pollution & Treatment Controls",
    "Publishing": "Publishing",
    "Railroads": "Railroads",
    "Real Estate - Development": "Real Estate - Development",
    "Real Estate - Diversified": "Real Estate - Diversified",
    "Real Estate Services": "Real Estate Services",
    "Recreational Vehicles": "Recreational Vehicles",
    "REIT - Diversified": "REIT - Diversified",
    "REIT - Healthcare Facilities": "REIT - Healthcare Facilities",
    "REIT - Hotel & Motel": "REIT - Hotel & Motel",
    "REIT - Industrial": "REIT - Industrial",
    "REIT - Mortgage": "REIT - Mortgage",
    "REIT - Office": "REIT - Office",
    "REIT - Residential": "REIT - Residential",
    "REIT - Retail": "REIT - Retail",
    "REIT - Specialty": "REIT - Specialty",
    "Rental & Leasing Services": "Rental & Leasing Services",
    "Residential Construction": "Residential Construction",
    "Resorts & Casinos": "Resorts & Casinos",
    "Restaurants": "Restaurants",
    "Scientific & Technical Instruments": "Scientific & Technical Instruments",
    "Security & Protection Services": "Security & Protection Services",
    "Semiconductor Equipment & Materials": "Semiconductor Equipment & Materials",
    "Semiconductors": "Semiconductors",
    "Semiconductors & Semiconductor Equipment": "Semiconductors & Semiconductor Equipment",
    "Software - Application": "Software - Application",
    "Software - Infrastructure": "Software - Infrastructure",
    "Software-Application": "Software-Application",
    "Solar": "Solar",
    "Specialty Business Services": "Specialty Business Services",
    "Specialty Chemicals": "Specialty Chemicals",
    "Specialty Industrial Machinery": "Specialty Industrial Machinery",
    "Specialty Retail": "Specialty Retail",
    "Staffing & Employment Services": "Staffing & Employment Services",
    "Steel": "Steel",
    "Telecom Services": "Telecom Services",
    "Textile Manufacturing": "Textile Manufacturing",
    "Thermal Coal": "Thermal Coal",
    "Tobacco": "Tobacco",
    "Tools & Accessories": "Tools & Accessories",
    "Travel Services": "Travel Services",
    "Trucking": "Trucking",
    "Uranium": "Uranium",
    "Utilities - Diversified": "Utilities - Diversified",
    "Utilities - Independent Power Producers": "Utilities - Independent Power Producers",
    "Utilities - Regulated Electric": "Utilities - Regulated Electric",
    "Utilities - Regulated Gas": "Utilities - Regulated Gas",
    "Utilities - Regulated Water": "Utilities - Regulated Water",
    "Utilities - Renewable": "Utilities - Renewable",
    "Utilities-Regulated Electric": "Utilities-Regulated Electric",
    "Waste Management": "Waste Management",
  },
  benchmark_categories: {
    General: "General",
    Sector: "Sector",
    Synthetic: "Sintético",
    Equity :"Renta Variable",
    Commodity : "Commodity",
    Bond : "Renta fija",
    Volatility : "Volatilidad"
  },
  pension_categories: {
    Mixed: "Mixto",
    Fixed: "Renta fija",
    Equity: "Renta Variable"
  },
  risks: {
    Medio: "Medio",
    "Muy reducido": "Muy reducido",
    "Medio/Bajo": "Medio/Bajo",
    Alto: "Alto",
    Reducido: "Reducido",
    "Muy alto": "Muy alto",
    "Medio/Alto": "Medio/Alto",
    null: "Nulo",
    Bajo: "Bajo",
  },
  legal_structures: {
    null: "Nulo",
    SICAV: "SICAV",
    "Unit Trust": "Unit Trust",
    "Open Ended Investment Company": "Open Ended Investment Company",
    FCP: "FCP",
  },
};
